import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams  } from 'react-router-dom';
import { ErrorComponent } from './ErrorComponent';
import { LoadingComponent } from './LoadingComponent';

export const PaidComponent = ({id, redirect, header}) => {

    const {t} = useTranslation();

    return (
        <div className="paidComponent">
            <div className="checkMark">&#10004;</div>
            <div className="paidHeader">{header}</div>
            <div className="paidContent">{t('invoice_paid_details', {invoiceId: id})}</div>   
            {redirect && <a href={'https://' + redirect}> {t('click_to_redirect')} {redirect}</a>}
        </div>
    ); 
}

export const PaidPage = () => {

    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);    
    const [id, setId] = useState(undefined);
    const [searchParams] = useSearchParams(); 
    const redirect = searchParams.get('redirect');

    useEffect(()=>{
        let zettrId = searchParams.get('unique_id');    

        if(zettrId)
            isReserved(zettrId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const isReserved = (zettrId) => {
        setLoading(true);
        axios.get('api/zettr/' + zettrId).then(result => {
            // payment is reserved
            let data = result.data;

            if(data.paidAt)
                setId(data.invoiceId);
            else
                setId(undefined);

        }).catch(error => {
            // payment not reserved
            setId(undefined);
        }).finally(()=>{
            setLoading(false);
        })        
    }    

    if(loading)
        return <LoadingComponent/>

    if(!id)
        return <ErrorComponent/>

    return <PaidComponent id={id} redirect={redirect} header={t('payment_received')}/>

}