import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import LandingPage from './LandingPage';
import { PaidPage } from './PaidComponent';
import './i18n';
import i18n from './i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

let language = localStorage.getItem('language');

if(language)
  i18n.changeLanguage(language);

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Routes>
      <Route path='/completed' element={<PaidPage/>}/>
      <Route path='/:id' element={<App/>}/>
      <Route path='' element={<LandingPage/>}/>
    </Routes>
  </BrowserRouter>,
  rootElement);

