import { useTranslation } from "react-i18next";

export const LoadingComponent = () => {

    const { t } = useTranslation();

    return (
        <div className="loadingDiv">
            <div className="loader"></div>
            {t('redirecting_to_payment')}
        </div>
    );

}