import { useTranslation } from "react-i18next";

export const ErrorComponent = () => {

    const {t} = useTranslation();

    return (
        <div className="errorComponent">
            <div className="errorHeader">{t('error_header')}</div>
            <div className="errorContent">{t('error_message')}</div>    
        </div>        
    );

}