import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './custom.css'
import { ErrorComponent } from './ErrorComponent';
import { LoadingComponent } from './LoadingComponent';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CollectionPage from './CollectionPage';
import { PaidComponent } from './PaidComponent';
import { useTranslation } from 'react-i18next';

const App = () =>{

  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  const { id } = useParams()
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(undefined);

  const [searchParams] = useSearchParams();

  useEffect(() => {

    if(id === 'undefined')
      navigate('/');

    getVismaPayUrl(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVismaPayUrl = async (id) => {

    let redirectUrl = searchParams.get("redirectUrl");
    let redirect = searchParams.get("redirect");
    let cancel = searchParams.get("cancelUrl");
    let language = searchParams.get("language");

    if(!language)
      language = 'no';

    i18n.changeLanguage(language);
    localStorage.setItem('language', language);         

    let json = {
      paymentUUID: id,
      redirectUrl: redirectUrl ?? redirect,
      cancelUrl: cancel,
      language: language
    };

    axios.post('/api/payment', json).then((result) => {

      if(!result.data.paid && !result.data.collection)
        window.location.replace(result.data.paymentUrl);

      setResult(result);

    }).finally(()=>setLoading(false));
  }

  if(loading)
    return <LoadingComponent/>
  else if(!result)
    return <ErrorComponent/>
  else if(result.data.collection)
    return <CollectionPage id={result.data.invoiceId}/>
  else if(result.data.paid)
    return <PaidComponent id={result.data.invoiceId} header={t('invoice_already_paid')}/>
}

export default App;
