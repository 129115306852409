import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const CollectionPage = ({id}) => {

    const {t} = useTranslation();

    return (
        <div className="collectionComponent">
            <div className="header">{t('collection_header', {invoiceId: id})}</div>
            <div className="content">
                <Trans i18nKey="collection_details">
                    Fakturaen din har gått til inkasso og kan ikke betales på denne måten. Vennligst logg deg inn på <a href="https://inkasso.visma.no/minside">Min Side</a> for å se/gjøre opp saken, eller ta kontakt på 73 20 60 00.
                </Trans>
                
            </div>    
        </div>        
    );
}

export default CollectionPage;