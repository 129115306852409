import React from 'react';

const LandingPage = () => {

    return (
        <div className="errorComponent">
            <div className="errorHeader">Pay</div>
            <div className="errorContent">En tjeneste levert av Amili</div>    
        </div>        
    );

}

export default LandingPage;