import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'no',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        no: {
            translation: {
                redirecting_to_payment: 'Henter betaling...',
                invoice_already_paid: 'Fakturaen er allerede betalt',
                invoice_paid_details: 'Faktura {{invoiceId}} er betalt',
                collection_details: 'Fakturaen din har gått til inkasso og kan ikke betales på denne måten. Vennligst logg deg inn på <1>Min Side</1> for å se/gjøre opp saken, eller ta kontakt på 73 20 60 00.',
                collection_header: 'Faktura {{invoiceId}}',
                click_to_redirect: "Trykk her for å gå til",
                payment_received: "Betaling mottatt",
                error_header: 'Uff da!',
                error_message: 'Kunne ikke hente betalingen din nå. Prøv igjen senere.'
            }
        },
        en: {
            translation: {
                redirecting_to_payment: 'Redirecting to payment...',
                invoice_already_paid: 'The invoice is already paid',
                invoice_paid_details: 'Invoice {{invoiceId}} is paid',
                collection_details: 'The invoice is transferred to debt collection. Please visit <1>Min Side</1> for more details and other payment options, or contact us on 73 20 60 00.',
                collection_header: 'Invoice {{invoiceId}}',
                click_to_redirect: "Click here to go to",
                payment_received: "Payment received",
                error_header: 'Oops!',
                error_message: 'Could not process your payment right now. Please try again later.'
            }
        }
    }
  });

export default i18n;